<template>
  <div class="policy external">
    <h1>Policy</h1>

    <div class="content">
      <p>
        While browsing the site, some of your information may be requested or
        collected, represented in the user's name, email, phone number for
        communication, age, gender, and preferred language. Contents is
        committed to maintaining the confidentiality of its information and not
        using it for purposes other than saving its users' data. It is also
        obligated not to publish or re-publish the data of any user's accounts
        without his consent.
      </p>
      <h2>Public policy</h2>
      <ol>
        <li>
          The site may contain other hyperlinks for the purpose of providing
          convenience to the user and providing a rich browsing experience and
          it is not responsible for the contents of other sites that these links
          lead to.
        </li>
        <li>
          The user may not reuse any contents published on the site, as it is
          literary owned by the contents.
        </li>
        <li>
          Zal reserves the right to prevent your access to its site if you
          violate the stated policies or perform any violations.
        </li>
        <li>
          zal may use cookies, or other technologies, on this website. Cookies
          are small files or records that we may place on your web browser for
          collecting information about your activities on the Website, preparing
          reports, assessing trends, and otherwise monitoring the way in which
          the Website is being used. This is intended to enhance convenience for
          you, and to assist us to run an efficient operation. By accepting
          these Terms, you confirm your consent to our use of cookies, and other
          such technologies, for these purposes.
        </li>
        <li>
          When one of the terms of this agreement is invalidated for any legal
          or regulatory purpose, this condition alone is canceled without
          affecting the rest of the conditions.
        </li>
        <li>
          Zal bears no responsibility when the site stops or cannot be viewed
          for any technical or activity-related reasons, with a promise to make
          it available for work as soon as possible.
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.external {
  padding: 130px 33px;

  h1 {
    text-align: center;
    font-size: 35px;
    color: #333;
  }

  .content {
    font-size: 15px;
    line-height: 1.7;
  }
}
</style>
