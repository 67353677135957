<template>
  <div class="terms external">
    <h1>Terms & Conditions</h1>

    <div class="content">
      <p>
        Contents Information Technology Company, a one-person company based in
        the Kingdom of Saudi Arabia, Riyadh, manages the website for its
        electronic shopping application, ZAL, www.elzal.com, hereinafter
        referred to as (ZAL, ZAL, we, the first party, the application, or
        Contents). The website is provided as a service to surfers in their
        various categories, with the aim of enabling them to get acquainted with
        the services, view our newsletters, facilitate communication with the
        application management, and register your interests. The Zal Management
        reserves the right to modify the contents of the site at any time
        without prior notice. Accordingly, you agree to the following terms as
        long as you start browsing the site: Terms and Conditions:
      </p>
      <ol>
        <li>
          These terms and conditions were drawn up between “Zal” and you in your
          capacity as the end user of the site, and according to the
          circumstances, the administration of Zal may make amendments to the
          terms and conditions, and for this reason the application management
          advises you to browse the terms and conditions on a regular basis to
          view any amendments that may occur to them. You are responsible for
          carefully reading, agreeing and accepting the terms and conditions and
          privacy policy when you browse the site each time.
        </li>
        <li>
          Your use of the site means that you accept the terms and conditions on
          the date of your browsing, and if you do not accept these provisions,
          you should stop browsing. User behavior and content:
          <ul>
            <li>
              You agree to comply with the laws in force in the Kingdom of Saudi
              Arabia, being the country concerned with your use of the website,
              and you acknowledge that you are fully aware of those laws and
              that you are in compliance with them.
            </li>
            <li>
              The site may provide an opportunity for users to post their
              comments, fill out questionnaires or subscription requests,
              inquire about providing a service or request a service, or submit
              suggestions or questions on this site or via e-mail or
              correspondence. Intellectual reference to others. You hereby grant
              Contents a perpetual, irrevocable, non-exclusive, royalty-free,
              transferable right and license to use your said User Content as
              you desire Contents. including, but not limited to, copying,
              modifying, deleting in its entirety, adapting, publishing,
              translating, copying derivative works from, selling and/or
              distributing said User Content Such User Content and/or combine
              such User Content with any other form, media or technology,
              without further reference to you, and you hereby waive any moral
              rights in such User Content. You also agree that you will not post
              any content that is contrary to public morals, defamatory,
              inflammatory, infringes intellectual property rights, or may
              otherwise violate public morals or the law.
            </li>
          </ul>
        </li>
        <li>
          Zal has the right to use, reproduce, disclose, modify, adapt, copy
          derivative works from, publish, display or distribute any User Content
          you have included for any purpose, without restriction and without any
          compensation to you in any way. Zal is not obligated in any way to:

          <ul>
            <li>maintain the confidentiality of any User Content;</li>
            <li>pay any compensation to a User for any User Content and</li>
            <li>respond to any User Content.</li>
          </ul>
        </li>
        <li>
          You agree that any User Content you include on the Website will not
          violate the Terms or any right of a third party, including but not
          limited to copyright, trademark, right(s) of confidentiality and other
          personal or proprietary rights, and not cause harm to any person or
          entity. You further agree that no User Content you post on the Site
          shall be considered or contain defamatory, defamatory, or otherwise
          illegal, intimidating, abusive, or obscene material and shall not
          contain software viruses, political campaigning, commercial
          solicitations, chain letters, bulk emails, or any other A form of
          "spam".
        </li>
        <li>
          Zal does not regularly review Posted User Content, but does reserve
          the right (but not the obligation) to monitor, edit or remove any User
          Content posted on the Website. You grant us the right to use the name
          that you include in connection with any User Content, and you agree
          not to use any false e-mail address, impersonate any person or entity,
          or otherwise mislead as to the original source of any User Content,
          and agree to indemnify us for any claims you create. for any User
          Content that you include, and Zal shall have no responsibility or
          liability for any User Content that you or any third party may
          include.
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.external {
  padding: 130px 33px;

  h1 {
    text-align: center;
    font-size: 35px;
    color: #333;
  }

  .content {
    font-size: 15px;
    line-height: 1.7;
  }
}
</style>
