<template>
  <div>
    <transition name="fadeInUp" mode="in-out">
      <div>
        <HeroSection />

        <AboutSection />

        <app-images />
        <app-download />

        <ContactUsSection />
      </div>
    </transition>
  </div>
</template>

<script>
// START:: IMPORTING MAIN LOADER
// END:: IMPORTING MAIN LOADER

// START:: IMPORTING LANDING COMPONENTS
import HeroSection from "../../components/LandingThreeComponents/mainSections/HeroSection.vue";
import AboutSection from "../../components/LandingThreeComponents/mainSections/AboutSection.vue";
import AppImages from "../../components/LandingThreeComponents/mainSections/AppImages.vue";
import AppDownload from "../../components/LandingThreeComponents/mainSections/AppDownload.vue";

// import Services from "../../components/LandingThreeComponents/mainSections/Services.vue";
// import TestimonialsSection from "../../components/LandingThreeComponents/mainSections/TestimonialsSection.vue";
// import CustomersSection from "../../components/LandingThreeComponents/mainSections/CustomersSection.vue";
import ContactUsSection from "../../components/LandingThreeComponents/mainSections/ContactUsSection.vue";

// END:: IMPORTING LANDING COMPONENTS

export default {
  name: "LandingThreeContent",

  components: {
    HeroSection,
    AboutSection,
    AppImages,
    AppDownload,
    // Services,
    // TestimonialsSection,
    // CustomersSection,
    ContactUsSection,
  },

  data() {
    return {
      // START:: MODAL APPERANCE DATA
      // END:: MODAL APPERANCE DATA
      // START:: PAGE CONTENT DATA
      // END:: PAGE CONTENT DATA
    };
  },

  methods: {
    // START:: TOGGLE MODAL
    // END:: TOGGLE MODAL
    // START:: GET PAGE CONTENT
    // END:: GET PAGE CONTENT
  },

  created() {
    // START:: FIRING METHODS
    // END:: FIRING METHODS
  },
};
</script>
