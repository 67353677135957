<template>
  <div id="about_section_three" class="about_section_three">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 order-2 order-md-1">
          <div class="title_wrapper">
            <h2>About Us</h2>
          </div>

          <div class="text_wrapper">
            ZAL application enables merchants, shops and service providers specialized in renting supplies for parties, events, trips, gatherings, entertainment, halls and conferences.
            Those who open an account in the application and create their own store enable them to display their products, manage their prices and offers, and receive orders from consumers seeking service.
            It also enables service providers to track orders until they are executed and completed and receive payments digitally.
            Detailed explanation of the application:
            ZAL application enables merchants, shops and service providers specialized in renting supplies for parties, events, trips, gatherings and entertainment
            From opening an account in the application, benefiting from exclusive features, and creating their own store, they can display their products, manage their prices and offers, and receive orders from consumers seeking service.
            It facilitates communication with the customer, receiving messages about requests and responding to them, reviewing incoming notifications and receiving payments digitally.
            It also enables the service provider to view and track his financial dues and send the request details to the customer.
            It also enables service providers to better effective marketing, improve the digital visibility of their facilities, track orders until their implementation and completion, and receive payments digitally easily. It also enhances the opportunity for the merchant to be present digitally and provide his services via the Internet.
            And other advantages in the application and benefit from updates and continuous development in the application.
            Service providers in various cities of the Kingdom will benefit from the application services according to the expansion of the available coverage.
            All this under the umbrella of technical support that is guaranteed throughout the working days.

          </div>
        </div>

        <div class="col-lg-6 order-1 order-md-2">
          <div class="section_image_wrapper">
            <img
              src="../../../assets/media/illustrations/trip2.svg"
              alt="About Us Section Image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingThreeAboutSection",

  props: {
    aboutTitle: {
      type: String,
      required: false,
    },
    aboutDesc: {
      type: String,
      required: false,
    },
  },
};
</script>
