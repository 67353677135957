<template>
  <footer class="landing_three_footer">
    <div class="container">
      <div class="footer_content">
        <div class="row justify-content-between">
          <div class="col-lg-2 my-3">
            <div class="logo_wrapper">
              <span class="zal">ZAL</span>
            </div>
          </div>

          <div class="col-lg-10 my-3">
            <div class="links_wrapper">
              <div class="wrapper">
                <h4>{{ $t("TITLES.essential_links") }}</h4>
                <ul class="footer_links_wrapper">
                  <li class="footer_link">
                    <button
                      class="active"
                      @click="helper_scrollToElement('hero_section_three')"
                    >
                      {{ $t("FOOTER.home") }}
                    </button>
                  </li>

                  <li class="footer_link">
                    <button
                      @click="helper_scrollToElement('about_section_three')"
                    >
                      {{ $t("FOOTER.about_us") }}
                    </button>
                  </li>

                  <li class="footer_link">
                    <button
                      @click="helper_scrollToElement('app_download_three')"
                    >
                      App Download
                    </button>
                  </li>

                  <li class="footer_link">
                    <button @click="helper_scrollToElement('app_images_three')">
                      Our App
                    </button>
                  </li>
                </ul>
              </div>

              <div class="wrapper">
                <h4>{{ $t("TITLES.contact_us") }}</h4>
                <ul class="footer_contact_data_wrapper">
                  <li class="footer_contact_item">
                    <span class="icon">
                      <i class="fal fa-phone-alt"></i>
                    </span>

                    <div class="text">+966505167111</div>
                  </li>

                  <li class="footer_contact_item">
                    <span class="icon">
                      <i class="fal fa-envelope"></i>
                    </span>

                    <div class="text">info@elzal.com</div>
                  </li>

                  <li class="footer_contact_item">
                    <span class="icon">
                      <i class="fal fa-map-marker-alt"></i>
                    </span>

                    <div class="text">Riyadh, Saudi Arabia</div>
                  </li>

                  <li class="footer_contact_item">
                    <div class="social">
                      <a href="#">
                        <i class="fab fa-facebook-square"></i>
                      </a>

                      <a href="#">
                        <i class="fab fa-linkedin"></i>
                      </a>

                      <a href="#">
                        <i class="fab fa-twitter-square"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
// END:: IMPORTING VUEX HELPERS

export default {
  name: "TheFooter",

  props: {},

  data() {
    return {
      // START:: CURRENT YEAR DATA
      currentYear: new Date().getFullYear(),
      // END:: CURRENT YEAR DATA
    };
  },

  methods: {
    // START:: SCROLL TO SECTION
    helper_scrollToElement(sectionId) {
      if (this.$route.path == "/") {
        const el = document.querySelector(`#${sectionId}`);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        new Promise((resolve) => {
          resolve(this.$router.push("/"));
        }).then(() => {
          setTimeout(() => {
            const el = document.querySelector(`${sectionId}`);
            el.scrollIntoView({ behavior: "smooth" });
          }, 2000);
        });
      }
    },
    // END:: SCROLL TO SECTION
  },
};
</script>
