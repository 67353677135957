<template>
  <section class="homeSection" id="app_download_three">
    <div class="bg">
      <div class="apps_side">
        <h2>
          Download <span class="zal">ZAL</span> <br />
          Application Now
        </h2>
        <div class="apps">
          <a href="">
            <img
              src="@/assets/media/images/landing_three/appStore.png"
              alt=""
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.develobug.zaluser&pcampaignid=web_share">
            <img
              src="@/assets/media/images/landing_three/googlePlay.png"
              alt=""
            />
          </a>
        </div>
      </div>

      <div class="apps_image">
        <img src="@/assets/media/images/landing_three/app_5.png" alt="" />
      </div>

      <div class="fixedImage"></div>

      <div class="bg_image">
        <img src="@/assets/media/images/landing_three/apps.png" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#app_download_three {
  .bg {
    width: 80%;
    margin: 0 auto;
    background: var(--theme_text_clr);
    border-radius: 15px;
    padding: 30px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media (max-width: 1250px) {
      justify-content: center;
      .apps_image {
        display: none;
      }
      .apps_side {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    @media (max-width: 700px) {
      width: 100%;
    }

    .apps_side {
      position: relative;
      z-index: 2;
      margin-left: 100px;

      h2 {
        color: #fff;
        font-size: 5rem;
        letter-spacing: 1.5px;
        margin: 20px 0;
        text-align: center;
      }
      .apps {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          display: block;
          margin-inline: 10px;

          img {
          }
        }
      }

      @media (max-width: 700px) {
        h2 {
          font-size: 2.5rem;
        }

        .apps {
          a {
            img {
              height: 50px;
            }
          }
        }
      }

      @media (max-width: 400px) {
        .apps {
          flex-direction: column;
        }
      }
    }

    .apps_image {
      position: relative;
      z-index: 2;
    }

    .bg_image {
      position: absolute;
      left: 0px;
      bottom: -12px;
      z-index: 1;

      @media (max-width: 1250px) {
        display: none;
      }
    }

    .fixedImage {
      position: absolute;
      top: -234px;
      right: -175px; // RTL
    }
  }

  img {
    border-radius: 15px;
  }
}

body.rtl {
  #app_download_three {
    .bg {
      .apps_side {
        margin-right: 100px;
      }
      .fixedImage {
        top: -234px;
        right: unset;
        left: -175px;
      }
    }
  }
}
</style>
