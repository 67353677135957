<template>
  <nav id="navbar" class="landing_three_header">
    <div class="container-fluid">
      <div class="navbar_wrapper">
        <div class="logo_wrapper">
          <router-link class="logo_wrapper" to="/">
            <span class="zal">ZAL</span>
          </router-link>
        </div>

        <div class="links_wrapper">
          <ul class="navbar_links_wrapper">
            <li class="navbar_link">
              <button
                class="active"
                @click="helper_scrollToElement('hero_section_three')"
              >
                {{ $t("NAVBAR.home") }}
              </button>
            </li>

            <li class="navbar_link">
              <button @click="helper_scrollToElement('about_section_three')">
                {{ $t("NAVBAR.about_us") }}
              </button>
            </li>

            <li class="navbar_link">
              <button @click="helper_scrollToElement('app_download_three')">
                Download App
              </button>
            </li>

            <li class="navbar_link">
              <button @click="helper_scrollToElement('app_images_three')">
                Our App
              </button>
            </li>

            <li class="navbar_link">
              <button @click="helper_scrollToElement('contact_us_three')">
                {{ $t("NAVBAR.contact_us") }}
              </button>
            </li>
            <li class="navbar_link">
              <router-link class="d-block" to="/products">
                Products
              </router-link>
            </li>

            <li class="navbar_link">
              <router-link class="d-block" to="/terms"> Terms </router-link>
            </li>

            <li class="navbar_link">
              <router-link class="d-block" to="/policy"> Policy </router-link>
            </li>
          </ul>
        </div>

        <div class="navbar_btns_wrapper">
          <button
            class="small_screens_navbar_toggeler"
            @click="toggleSmallScreensNavBar"
          >
            <i class="fal fa-bars"></i>
          </button>
        </div>
      </div>
    </div>

    <div
      class="small_screens_navbar_wrapper"
      :class="{ active: smallScreensNavbarIsOpen }"
    >
      <button class="close_btn" @click="toggleSmallScreensNavBar">
        <i class="fal fa-times"></i>
      </button>

      <ul class="small_screens_links">
        <li>
          <button
            class="active"
            @click="
              toggleSmallScreensNavBar();
              helper_scrollToElement('hero_section_three');
            "
          >
            {{ $t("NAVBAR.home") }}
          </button>
        </li>

        <li>
          <button
            @click="
              toggleSmallScreensNavBar();
              helper_scrollToElement('about_section_three');
            "
          >
            {{ $t("NAVBAR.about_us") }}
          </button>
        </li>

        <li class="navbar_link">
          <button @click="helper_scrollToElement('app_download_three')">
            Download App
          </button>
        </li>

        <li class="navbar_link">
          <button @click="helper_scrollToElement('app_images_three')">
            Our App
          </button>
        </li>

        <li>
          <button
            @click="
              toggleSmallScreensNavBar();
              helper_scrollToElement('contact_us_three');
            "
          >
            {{ $t("NAVBAR.contact_us") }}
          </button>
        </li>
        <li>
          <router-link class="d-block" to="/products"> Products </router-link>
        </li>

        <li>
          <router-link class="d-block" to="/terms"> Terms </router-link>
        </li>

        <li>
          <router-link class="d-block" to="/policy"> Policy </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

export default {
  name: "TheHeader",

  props: {
    appActiveLang: {
      type: String,
      required: true,
    },
  },

  computed: {
    // START:: VUEX GET APP THEME
    ...mapGetters("AppThemeModule", ["getAppTheme"]),
    // END:: VUEX GET APP THEME

    // START:: VUEX GET APP LANG
    ...mapGetters("AppLangModule", ["getAppLocale"]),
    // END:: VUEX GET APP LANG
  },

  data() {
    return {
      // START:: SMALL SCREENS NAVBAR APPERANCE DATA
      smallScreensNavbarIsOpen: false,
      // END:: SMALL SCREENS NAVBAR APPERANCE DATA

      // START:: USER PROFILE MENU APPERANCE DATA
      langMenuIsOpen: false,
      // END:: USER PROFILE MENU APPERANCE DATA
    };
  },

  methods: {
    // START:: VUEX APP LANG ACTIONS
    ...mapActions("AppLangModule", ["changeAppLanguage"]),
    // END:: VUEX APP LANG ACTIONS

    // START:: VUEX  APP THEME ACTIONS
    ...mapActions("AppThemeModule", ["changeAppTheme"]),
    // END:: VUEX APP THEME ACTIONS

    // START:: TOGGLE SMALL SCREENS NAVBAR
    toggleSmallScreensNavBar() {
      this.smallScreensNavbarIsOpen = !this.smallScreensNavbarIsOpen;
    },
    // END:: TOGGLE SMALL SCREENS NAVBAR

    // START:: CHECK ROUTE
    checkRoute() {
      if (this.$route.path.includes("/start-now")) {
        this.$router.push("/");
      } else {
        return;
      }
    },
    // END:: CHECK ROUTE

    // START:: TOGGLE PROFILE MENU
    toggleLangMenu() {
      this.langMenuIsOpen = !this.langMenuIsOpen;
    },
    // END:: TOGGLE PROFILE MENU

    // START:: SCROLL TO SECTION

    helper_scrollToElement(sectionId) {
      if (this.$route.path == "/") {
        const el = document.querySelector(`#${sectionId}`);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        new Promise((resolve) => {
          resolve(this.$router.push("/"));
        }).then(() => {
          setTimeout(() => {
            const el = document.querySelector(`${sectionId}`);
            el.scrollIntoView({ behavior: "smooth" });
          }, 2000);
        });
      }
    },
    // END:: SCROLL TO SECTION
  },

  created() {
    window.addEventListener("click", () => {
      this.langMenuIsOpen = false;
      this.notificationsMenuIsOpen = false;
    });
  },

  mounted() {
    if (this.appActiveLang != "both") {
      this.changeAppLanguage({
        newLang: this.appActiveLang,
        firedInMounted: true,
      });
    }
  },
};
</script>
<style scoped>
.zal {
  font-size: 72px;
  background: -webkit-linear-gradient(#8b004c, #f5309b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
