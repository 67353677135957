<template>
  <div id="contact_us_three" class="contact_us_three">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-6 my-6">
          <div class="contact_form_wrapper">
            <div
              class="title_wrapper"
              v-html="$t('TITLES.contact_us_html')"
            ></div>

            <div class="subtitle mb-2">
              Service providers in various cities of the Kingdom will benefit from the application services according to the expansion of the available coverage.
              All this under the umbrella of technical support that is guaranteed throughout the working days.
            </div>

            <form @submit.prevent="validateContactFormInputs">
              <div class="row">
                <div class="col-lg-12 my-3">
                  <div class="input_wrapper">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('PLACEHOLDERS.name')"
                      v-model.trim="contactFormData.name"
                    />
                  </div>
                </div>

                <div class="col-lg-12 my-3">
                  <div class="input_wrapper">
                    <input
                      type="email"
                      class="form-control"
                      :placeholder="$t('PLACEHOLDERS.email')"
                      v-model.trim="contactFormData.email"
                    />
                  </div>
                </div>

                <div class="col-lg-12 my-3">
                  <div class="input_wrapper">
                    <textarea
                      class="form-control"
                      :placeholder="$t('PLACEHOLDERS.message')"
                      v-model.trim="contactFormData.message"
                      rows="3"
                    ></textarea>
                  </div>
                </div>

                <div class="btn_wrapper">
                  <button>
                    <span>
                      {{ $t("BUTTONS.send") }}
                    </span>
                    <span class="icon">
                      <i class="fal fa-long-arrow-right"></i>
                    </span>
                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-lg-5 my-6">
          <div class="section_image_wrapper">
            <img
              src="../../../assets/media/illustrations/Contact.svg"
              alt="Contact Us Section Image"
              width="180"
              height="180"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingThreeContactUsSection",

  data() {
    return {
      // START:: LOADER HANDLING DATA
      isWaitingRequest: false,
      // END:: LOADER HANDLING DATA

      // START:: SECTION DESC DATA
      sectionDesc: null,
      // END:: SECTION DESC DATA

      // START:: CONTACT FORM DATA
      contactFormData: {
        name: null,
        email: null,
        message: null,
      },
      // END:: CONTACT FORM DATA
    };
  },

  methods: {
    // START:: GET SECTION DESCRIPTION
    getSectionDesc() {
      this.$axios({
        method: "GET",
        url: `footer`,
      })
        .then((res) => {
          this.sectionDesc = res.data.data.contact_title;
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    },
    // END:: GET SECTION DESCRIPTION

    // START:: VALIDATE CONTACT FORM INPUTS
    validateContactFormInputs() {
      this.isWaitingRequest = true;

      if (!this.contactFormData.name) {
        this.isWaitingRequest = false;
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("VALIDATION.name"),
          messageSize: "22",
          position: this.$t("iziToastConfigs.position"),
          rtl: this.$t("iziToastConfigs.dir"),
        });
        return;
      } else if (!this.contactFormData.email) {
        this.isWaitingRequest = false;
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("VALIDATION.email"),
          messageSize: "22",
          position: this.$t("iziToastConfigs.position"),
          rtl: this.$t("iziToastConfigs.dir"),
        });
        return;
      } else if (!this.contactFormData.message) {
        this.isWaitingRequest = false;
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("VALIDATION.message"),
          messageSize: "22",
          position: this.$t("iziToastConfigs.position"),
          rtl: this.$t("iziToastConfigs.dir"),
        });
        return;
      } else {
        this.submitContactForm();
      }
    },
    // END:: VALIDATE CONTACT FORM INPUTS

    // START:: SUBMIT CONTACT FORM
    submitContactForm() {
      this.isWaitingRequest = true;

      const theData = new FormData();
      // START:: APPEND CONTACT DATA
      theData.append("fullname", this.contactFormData.name);
      theData.append("email", this.contactFormData.email);
      theData.append("content", this.contactFormData.message);
      // END:: APPEND CONTACT DATA

      // START:: SEND REQUEST
      this.$axios({
        method: "POST",
        url: "contact",
        data: theData,
      })
        .then(() => {
          this.isWaitingRequest = false;
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("MESSAGES.sent_successfully"),
            messageSize: "22",
            position: this.$t("iziToastConfigs.position"),
            rtl: this.$t("iziToastConfigs.dir"),
          });
          this.clearContactFormData();
        })
        .catch((err) => {
          this.isWaitingRequest = false;
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            messageSize: "22",
            position: this.$t("iziToastConfigs.position"),
            rtl: this.$t("iziToastConfigs.dir"),
          });
        });
      // END:: SEND REQUEST
    },
    // END:: SUBMIT CONTACT FORM

    // START:: CLEAR FORM INPUTS DATA
    clearContactFormData() {
      this.contactFormData.name = null;
      this.contactFormData.email = null;
      this.contactFormData.message = null;
    },
    // END:: CLEAR FORM INPUTS DATA
  },

  created() {
    // START:: FIRE METHODS
    this.getSectionDesc();
    // END:: FIRE METHODS
  },
};
</script>
