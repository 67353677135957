<template>
  <div id="hero_section_three" class="hero_section_three">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <div class="hero_text_wrapper">
            <h1 class="hero_title">We Enlight Your Happines</h1>

            <div class="text_wrapper">
              we provide of rental services for parties, events, camping and
              trips in all regions of the Kingdom to display their products and
              enable them to achieve better sales. It also makes it easier for
              consumers to browse the offered products and services and request
              their needs through the availability of many offered opportunities
              and payment with multiple digital payment options and payment on
              delivery.
            </div>
          </div>
        </div>

        <div class="col-md-6 order-1 order-md-2">
          <div class="hero_image_wrapper">
            <img
              src="../../../assets/media/illustrations/trip4.svg"
              alt="Hero Section Image"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mouse_wrapper">
      <button
        class="scroll_btn"
        @click="scrollToSection('about_section_three')"
      >
        <img
          src="../../../assets/media/icons/mouse.svg"
          width="30"
          height="40"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingThreeHeroSection",

  methods: {
    // START:: SCROLL TO SECTION
    scrollToSection(section_id) {
      const selected_section = document.querySelector(`#${section_id}`);
      selected_section.scrollIntoView();
    },
    // END:: SCROLL TO SECTION
  },
};
</script>
