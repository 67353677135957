<template>
  <div class="policy external">
    <Product v-for="product in products" :key="product.id" :product="product" />
  </div>
</template>

<script>
import Product from "../../components/LandingThreeComponents/mainSections/Product.vue";
export default {
  data() {
    return {
      products: [
        {
          id: 1,
          title: "مهرجين",
          desc: "توفير ممثل بزي تنكري جذاب يقدم محتوى ترفيهي وتفاعلي للأطفال في المناسبات وأعياد الميلاد",
          price: "500  ",
          image: [
            "https://www.sayidaty.net/sites/default/files/styles/900_scale/public/2017/09/21/2930346-944681795.jpg",
          ],
        },
        {
          id: 2,
          title: "صبابين وصبابات قهوة ومشروبات",
          desc: "توفير صبابين وصبابات قهوة للمناسبات حسب طلب العميل",
          price: "500 ",
          image: ["https://elzal.com/android-chrome-512x512.png"],
        },
        {
          id: 3,
          title: "كراسي ذات مسند ظهر مع كسوة فئة أ",
          desc: "كرسي ذو مسند من المعدن حسب طلب العميل مع تلبيسه من القماش تتوفر بألوان متعددة",
          price: "25 ",
          image: [
            "https://ae01.alicdn.com/kf/Hc2cdd0fc549a4c9abdb4c503849bba70D/LQ-L1030RR.jpg_Q90.jpg_.webp",
          ],
        },
        {
          id: 4,
          title: "كراسي ذات مسند ظهر مع كسوة فئة ب",
          desc: "كرسي ذو مسند من الاكريليك أو البلاستيك حسب طلب العميل مع تلبيسه من القماش تتوفر بألوان متعددة",
          price: "18 ",
          image: [
            "https://s.alicdn.com/@sc04/kf/HTB1D0LjasrrK1RjSspaq6AREXXac.jpg_720x720q50.jpg",
          ],
        },
        {
          id: 5,
          title: "كراسي ذات مسند ظهر مع كسوة فئة ج",
          desc: "كرسي ذو مسند من المعدن حسب طلب العميل مع تلبيسه من القماش تتوفر بألوان متعددة",
          price: "12 ",
          image: ["https://elzal.com/android-chrome-512x512.png"],
        },
        {
          id: 6,
          title: "طاولة تقديم",
          desc: "طاولة من المعدن المذهب وسطح رخامي ",
          price: "40 ",
          image: [
            "https://kemittupload.s3.eu-central-1.amazonaws.com/img/product/images-1646061753615.jpg",
          ],
        },
        {
          id: 7,
          title: "كرسي مميز من الجلد",
          desc: "كرسي مريح مميز من الجلد المبطن باسفنج سميك",
          price: "90 ",
          image: [
            "https://www.homesdirect365.co.uk/images/antique-french-style-regence-arm-chair-p52204-65847_zoom.jpg",
          ],
        },
        {
          id: 8,
          title: "سماعات صوت",
          desc: "سماعات صوتية مع ميكروفون وميكسر",
          price: "300 ",
          image: [
            "https://api.mzadqatar.com/uploads/thumbnail/2021/10/20/15320021-e1f2807782.png",
          ],
        },
        {
          id: 9,
          title: "سماعات صوت",
          desc: "سماعات بقدرة 5000 وات مع ميكروفون وميكسر",
          price: "900 ",
          image: [
            "https://images.yaoota.com/RJgOIGeKiX822f-XUsnv2_woaBY=/trim/fit-in/500x500/filters:quality(80)/yaootaweb-production-sa/media/crawledproductimages/ddbb0bdebcf59db7279315153a294e840da1bcba.jpg",
          ],
        },
        {
          id: 10,
          title: "مسلاط اضاءة ملونة",
          desc: "مسلاط اضاءة ملونة للتأثيرات الخاصة",
          price: "600 ",
          image: [
            "https://ae01.alicdn.com/kf/Se47f103ae28745a68316a847e424d4d4F/DJ-RGB.jpg_640x640.jpg",
          ],
        },
        {
          id: 11,
          title: "جلسات ارضية حجم 4 افراد",
          desc: "جلسات ارضية حجم عدد 4 افراد",
          price: "70 ",
          image: [
            "https://rowadmedia.com.sa/wp-content/uploads/2019/11/%D8%A7%D9%84%D8%A7%D8%AB%D8%A7%D8%AB11.jpg",
          ],
        },
        {
          id: 12,
          title: "سجادة مقاس 2 * 2 م",
          desc: "سجادة ارضية مقاس 2 * 2 م",
          price: "30 ",
          image: [
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUcuUf1HFaycc3HVsNEIOc31OStOcVHGub0g&usqp=CAU",
          ],
        },
        {
          id: 13,
          title: "خيمة من بيت الشعر مقاس 4 * 4 م",
          desc: "خيمة من بيت الشعر بالمقاس المحدد يمكن زيادة الحجم حسب طلب العميل وتتغير التكلفة حسب الطلب النهائي",
          price: "1000 ",
          image: [
            "https://www.eftkar.com/wp-content/uploads/2015/02/%D8%A8%D9%8A%D9%88%D8%AA-%D8%B4%D8%B9%D8%B1-%D8%AC%D8%A7%D9%87%D8%B2%D8%A9.jpg",
          ],
        },
      ],
    };
  },
  components: {
    Product,
  },
};
</script>

<style lang="scss" scoped>
.external {
  padding: 130px 33px;

  h1 {
    text-align: center;
    font-size: 35px;
    color: #333;
  }

  .content {
    font-size: 15px;
    line-height: 1.7;
  }
}
</style>
