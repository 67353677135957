<template>
  <div id="app_images_three">
    <h1 class="text-center my-5">Our App</h1>
    <div class="swiper">
      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="img in images" :key="img">
            <img :src="img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require("@/assets/media/images/landing_three/app_1.png"),
        require("@/assets/media/images/landing_three/app_2.png"),
        require("@/assets/media/images/landing_three/app_3.png"),
        require("@/assets/media/images/landing_three/app_4.png"),
        require("@/assets/media/images/landing_three/app_5.png"),
        require("@/assets/media/images/landing_three/app_6.png"),
      ],

      options: {
        center: true,
        items: 1,
      },
    };
  },

  mounted() {
    // eslint-disable-next-line
    new Swiper(".mySwiper", {
      effect: "cards",
      grabCursor: true,
    });
  },
};
</script>

<style lang="scss">
#app_images_three {
  margin: 50px auto 100px auto;
  width: 90%;

  .swiper {
    max-width: 380px;
    overflow: visible;
  }

  .swiper-slide {
    position: relative;
    img {
      border-radius: 15px;
    }
  }
}
</style>
