<template :key="$route.path">
  <div class="landing_three">
    <TheHeader :appActiveLang="activeLang" />

    <router-view />

    <TheFooter />
  </div>
</template>

<script>
// START:: IMPORTING LAYOUTS
import TheHeader from "../../components/LandingThreeComponents/layouts/TheHeader.vue";
import TheFooter from "../../components/LandingThreeComponents/layouts/TheFooter.vue";
// END:: IMPORTING LAYOUTS

export default {
  name: "LandingThreeHomePage",

  components: {
    TheHeader,
    TheFooter,
  },

  data() {
    return {
      // START:: ACTIVE LANG DATA
      activeLang: null,
      // END:: ACTIVE LANG DATA

      // START:: FOOTER DATA
      footerData: null,
      // END:: FOOTER DATA
    };
  },

  methods: {
    // START:: GET FOOTER CONTENT
    // END:: GET FOOTER CONTENT
  },

  created() {
    // START:: FIRING METHODS
    // END:: FIRING METHODS
  },
};
</script>
