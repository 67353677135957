<template>
  <div class="content_wrapper_wrapper">
    <section class="main-content">
      <transition name="fadeInUp" mode="out-in">
        <router-view />
      </transition>
    </section>
  </div>
</template>

<script>
export default {
  name: "AppContentWrapper",
};
</script>
